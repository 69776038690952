import React from 'react';
import { TextField } from "@mui/material";

interface WrappedTextFieldProps {
    id: string;
    label: string;
    value: string;
    required?: boolean | false;
    multiline?: boolean | false;
    disabled?: boolean | false;
    onChange: (newValue: string) => void;
}

const WrappedTextField: React.FC<WrappedTextFieldProps> = (props) => {
    const[error, setError] = React.useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
        if(props.required && event.target.value === '') {
            setError(true);
        }else {
            setError(false);
        }
    };

    return (
        <TextField
            id={props.id}
            name={props.id}
            label={props.label}
            value={props.value ?? ''}
            required = {props.required}
            multiline={props.multiline}
            disabled={props.disabled}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            error={error}
        />
    );
};
export default WrappedTextField;