import React, { useState } from "react";

const HomeDashboard = () => {
    document.title = "Dashboard | Velzon - React Admin & Dashboard Template";

    const [rightColumn, setRightColumn] = useState<boolean>(true);
    const toggleRightColumn = () => {
        setRightColumn(!rightColumn);
    };

    return (
        <div>
            <h1>Home Dashboard</h1>
        </div>
    );
};

export default HomeDashboard;
