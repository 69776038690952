import englishFlag from "../assets/images/flags/us.svg";
import frenchFlag from "../assets/images/flags/french.svg";
import malagasyFlag from "../assets/images/flags/malagasy.svg";


const languages = {
  fr: {
    label: "Français",
    flag: frenchFlag,
  },
  mg: {
    label: "Malagasy",
    flag: malagasyFlag,
  },
  en: {
    label: "English",
    flag: englishFlag,
  }
}

export default languages
