import React, { useEffect, useState } from "react";
import { EntityStorageManager } from "../../../../utils/state/entity-storage-manager";
import { EntityStorageType } from "../../../../utils/state/entity-storage-type";
import { EntityStorageScope } from "../../../../utils/state/entity-storage-scope";
import { EntityStorageKey } from "../../../../utils/state/entity-storage-keys";
import { getFamilies, getGroups } from "../../../../services/user-service";
import Autocomplete from "@mui/material/Autocomplete";
import { Form } from "reactstrap";
import { Checkbox, Grid2, ListItemText, TextField } from "@mui/material";

interface SecondStepFormProps {
    onCancel: () => void
    onValidation: (result: boolean) => void
}


export interface AutoCompleteOption {
    id: string;
    name: string;
}


const SecondStepForm: React.FC<SecondStepFormProps> = (props) => {
    const userCreationInfoStorageManager = new EntityStorageManager(EntityStorageType.User, EntityStorageScope.Create);
    const userFamily = userCreationInfoStorageManager.getOrDefault(EntityStorageKey.Family, null);
    const userGroups = userCreationInfoStorageManager.getOrDefault(EntityStorageKey.Groups, null);
    const [familyOptions, setFamilyOptions] = useState<any>(userFamily ? [userFamily] : []);
    const [groupOptions, setGroupOptions] = useState<any>(userGroups ? userGroups.length > 1 ? userGroups : [userGroups] : []);
    const [selectedFamilyOption, setSelectedFamilyOption] = useState('' as any);
    const [selectedGroups, setSelectedGroups] = useState('' as any);

    useEffect(() => {
        getFamilies().then(setFamilyOptions);
        getGroups().then(setGroupOptions);
    }, []);

    const validate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        userCreationInfoStorageManager.set(EntityStorageKey.Family, selectedFamilyOption);
        userCreationInfoStorageManager.set(EntityStorageKey.Groups, selectedGroups);

        props.onValidation(true);
    }

    const handleGroupsChange = (event: any, newValue: any) => {
        setSelectedGroups(newValue);
    };


    function cancel() {
        props.onCancel();
    }


    return (
        <div>
            <div>
                <h5>Step 2/3 - Links (Family, Groups)</h5>
                <p className="text-muted">
                    Fill all information below
                </p>
            </div>
            <Form onSubmit={validate}>
                <div>
                    <Grid2 container spacing={3}>
                        <Grid2 size={4}>
                            <Autocomplete
                                options={familyOptions}
                                getOptionKey={(option: AutoCompleteOption) => option.id}
                                getOptionLabel={(option: AutoCompleteOption) => option.name}
                                onChange={(event, value) => setSelectedFamilyOption(value)}
                                renderInput={(params) => <TextField {...params} label="Family"/>}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Autocomplete
                                options={groupOptions}
                                multiple={true}
                                getOptionKey={(option: AutoCompleteOption) => option.id}
                                getOptionLabel={(option: AutoCompleteOption) => option.name}
                                renderInput={(params) => <TextField {...params} label="Groups"/>}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            onChange={() => handleGroupsChange({}, selected ? selectedGroups.filter((o: AutoCompleteOption) => o !== option) : [...selectedGroups, option])}
                                        />
                                        <ListItemText primary={option.name} />
                                    </li>
                                )}
                            />
                        </Grid2>
                    </Grid2>
                </div>
                <div className="d-flex align-items-start gap-3 mt-4">
                    <button
                        type="button"
                        className="btn btn-light btn-label previestab"
                        onClick={() => {
                            cancel();
                        }}>
                        <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                        Back to personal info
                    </button>
                    <button
                        type="submit"
                        className="btn btn-success btn-label right ms-auto nexttab">
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Go to roles
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default SecondStepForm;