import {
    Card,
    CardBody,
    CardHeader,
    Nav,
    TabContent,
    TabPane
} from "reactstrap";
import React, { useState } from "react";
import Grid2 from "@mui/material/Grid2";
import FirstStepForm from "./first-step-form";
import SecondStepForm from "./second-step-form";
import ThirdStepForm from "./third-step-form";
import FourthStepForm from "./fourth-step-form";
import NavigationStepItem from "./navigation-step-item";

const AddUserForm = () => {

    const [activeVerticalTab, setActiveVerticalTab] = useState(1);
    const [passedverticalSteps, setPassedverticalSteps] = useState([1]);

    const validateFirstStep = (result: boolean) => {
        if (result) {
            gotToNextStep();
        }
    }

    const validateSecondStep = (result: boolean) => {
        if (result) {
            gotToNextStep();
        }
    }
    const validateThirdStep = (result: boolean) => {
        if (result) {
            gotToNextStep();
        }
    }
    const gotToNextStep = () => {
        toggleVerticalTab(activeVerticalTab + 1);
    }
    const goToPreviousStep = () => {
        toggleVerticalTab(activeVerticalTab - 1);
    }

    function toggleVerticalTab(tab: number) {
        if (activeVerticalTab !== tab) {
            const modifiedSteps = [...passedverticalSteps, tab];
            setActiveVerticalTab(tab);
            setPassedverticalSteps(modifiedSteps);
        }
    }

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Add new user</h4>
            </CardHeader>
            <CardBody>
                <div className="vertical-navs-step form-steps">
                    <Grid2 container spacing={0}>
                        <Grid2 size={3}>
                            <Nav className="flex-column custom-nav nav-pills">
                                <NavigationStepItem itemId={1} currentTab={activeVerticalTab} updateSelectedTab={toggleVerticalTab} stepTitle="Personal Info"/>
                                <NavigationStepItem itemId={2} currentTab={activeVerticalTab} updateSelectedTab={toggleVerticalTab} stepTitle="Links (Family, Groups)"/>
                                <NavigationStepItem itemId={3} currentTab={activeVerticalTab} updateSelectedTab={toggleVerticalTab} stepTitle="Roles"/>
                                <NavigationStepItem itemId={4} currentTab={activeVerticalTab} updateSelectedTab={toggleVerticalTab} stepTitle="Finish"/>
                            </Nav>
                        </Grid2>
                        <Grid2 size={9}>
                            <div className="px-lg-4">
                                <TabContent activeTab={activeVerticalTab}>
                                    <TabPane tabId={1}>
                                        <FirstStepForm onValidation={validateFirstStep}/>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <SecondStepForm onCancel={goToPreviousStep} onValidation={validateSecondStep}/>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <ThirdStepForm onCancel={goToPreviousStep} onValidation={validateThirdStep}/>
                                    </TabPane>
                                    <TabPane tabId={4}>
                                        <FourthStepForm/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Grid2>
                    </Grid2>
                </div>
            </CardBody>
        </Card>
    );
}
export default AddUserForm;