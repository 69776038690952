import { EntityStorageScope } from "./entity-storage-scope";
import { EntityStorageType } from "./entity-storage-type";
import { LocalStorageManager } from "./local-storage-manager";

export class EntityStorageManager {
    private readonly type: string;
    private readonly scope: string;

    constructor(type: EntityStorageType, scope: EntityStorageScope) {
        this.type = type;
        this.scope = scope;
    }

    public set(key: string, value: any): void {
        LocalStorageManager.set(`${this.type}-${this.scope}-${key}`, value);
    }

    public get(key: string): any {
        return LocalStorageManager.get(`${this.type}-${this.scope}-${key}`);
    }

    public getOrDefault(key: string, defaultValue: any): any  {
        const value = LocalStorageManager.get(`${this.type}-${this.scope}-${key}`);
        return value === null ? defaultValue : value;
    }

    public remove(key: string): void {
        LocalStorageManager.remove(`${this.type}-${this.scope}-${key}`);
    }

    public clear(): void {
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith(`${this.type}-${this.scope}`)) {
                localStorage.removeItem(key);
            }
        });
    }
}
