import React from 'react';
import { Box, Paper, Tab, TabProps, Tabs } from '@mui/material';

interface TabContent {
    name: string;
    element?: React.JSX.Element;
    link?: string;
    icon?: React.JSX.Element;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface SubcategoryMenuProps {
    tabContents: TabContent[];
}

const SubCategoryMenu: React.FC<SubcategoryMenuProps> = ({tabContents}) => {
    const [value, setValue] = React.useState(0);

    const getProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleChange = (event: React.SyntheticEvent, index: number) => {
        const tabContent = tabContents[index];
        if (tabContent.link) {
            window.open(tabContent.link, '_blank');
        } else {
            setValue(index);
        }
    };

    function CustomTabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{p: 3}}>{children}</Box>}
            </div>
        );
    };

    function CustomTab(props: TabProps) {
        const {icon, label, ...other} = props;
        return (
            <Tab
                icon={
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                        {icon}
                        {label}
                    </Box>
                }
                iconPosition="start"
                {...other}
            />
        );
    };

    return (
        <Paper>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} centered >
                        {tabContents.map((tabContent, index) => (
                            <CustomTab
                                key={index}
                                label={tabContent.name}
                                icon={tabContent.icon}
                                {...getProps(index)}
                            />
                        ))}
                    </Tabs>
                </Box>
                {tabContents.map((tabContent, index) => (
                    <CustomTabPanel key={index} value={value} index={index}>
                        {tabContent.element}
                    </CustomTabPanel>
                ))}
            </Box>
        </Paper>
    );
}

export default SubCategoryMenu;