import React from "react";

interface FourthStepFormProps {
}

const FourthStepForm: React.FC<FourthStepFormProps> = () => {
    return (
        <div className="text-center pt-4 pb-2">
            <div className="mb-4">
                <i className="bx bx-party display-4 text-success"></i>
            </div>
            <h5>Your Order is Completed !</h5>
            <p className="text-muted">
                You Will receive an order confirmation email
                with details of your order.
            </p>
        </div>
    );
}

export default FourthStepForm;