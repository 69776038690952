import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "reactstrap";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { getConnectedUser, updateProfile } from "../../services/user-service";
import { userUpdate } from "../../reducers/user-reducer";
import CustomSnackbar, { handleSnackbarClose } from "../../Components/Custom/Messages/snackbar";
import WrappedTextField from "../../Components/Custom/TextFields/wrapped-text-field";
import { getAge } from "../../utils/date-helper";
import GoogleMapsField from "../../Components/Custom/TextFields/google-maps-field";
import WrappedDateField from "../../Components/Custom/TextFields/wrapped-date-field";
import WrappedButton from "../../Components/Custom/Buttons/wrapped-button";

const PersonalDetails: React.FC = (props: any) => {

    const dispatch: any = useDispatch();
    const [personalUserId, setPersonalUserId] = useState("");
    const [personalGender, setPersonalGender] = useState(-1);
    const [personalUsername, setPersonalUsername] = useState("");
    const [personalFirstName, setPersonalFirstName] = useState("");
    const [personalMiddleName, setPersonalMiddleName] = useState("");
    const [personalLastName, setPersonalLastName] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [personalHomePhone, setPersonalHomePhone] = useState("");
    const [personalMobilePhone, setPersonalMobilePhone] = useState("");
    const [personalAddress, setPersonalAddress] = useState("");
    const [personalBirthDate, setPersonalBirthDate] = useState("");
    const [personalDescription, setPersonalDescription] = useState("");
    const [personalIsActive, setPersonalIsActive] = useState(-1); 
    
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("info" as "info" | "success" | "warning" | "error");

    useEffect(() => {
        getConnectedUser().then((user) => {
            if (user) {
                setPersonalUserId(user.userId);
                setPersonalGender(user.gender);
                setPersonalUsername(user.username);
                setPersonalFirstName(user.firstName);
                setPersonalMiddleName(user.middleName);
                setPersonalLastName(user.lastName);
                setPersonalEmail(user.email);
                setPersonalHomePhone(user.homePhone);
                setPersonalMobilePhone(user.mobilePhone);
                setPersonalAddress(user.address);
                setPersonalBirthDate(user.birthDate);
                setPersonalDescription(user.description);
                setPersonalIsActive(user.isActive);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const save = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const updatedUser = {
            userId: personalUserId,
            username: personalUsername,
            gender: personalGender,
            firstName: personalFirstName,
            middleName: personalMiddleName,
            lastName: personalLastName,
            email: personalEmail,
            homePhone: personalHomePhone,
            mobilePhone: personalMobilePhone,
            address: personalAddress,
            birthDate: personalBirthDate,
            description: personalDescription,
            isActive: personalIsActive,
            profilePicture: null
        };
        updateProfile(updatedUser)
            .then((response) => {
                dispatch(userUpdate({
                    firstName: personalFirstName,
                    lastName: personalLastName,
                    profilePicture: null
                }));
                setSnackbarMessage("Profil mis à jour avec succès");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarMessage(error);
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    return (
        <Form onSubmit={save}>
            <Row>
                <Col lg={12}>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={handleSnackbarClose(setSnackbarOpen)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                </Col>
                <Col lg={12}>
                    <FormControl>
                        <InputLabel id="select-label">Genre</InputLabel>
                        <Select
                            value={personalGender}
                            label="Genre"
                            variant={"outlined"}
                            disabled={true}
                        >
                            <MenuItem value={0}>Homme</MenuItem>
                            <MenuItem value={1}>Femme</MenuItem>
                        </Select>
                    </FormControl>
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"lastName"} label={"Nom"} value={personalLastName} required={true}
                                      onChange={(newValue) => setPersonalLastName(newValue)} />
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"firstName"} label={"Prénom principal"} value={personalFirstName}
                                      required={true}
                                      onChange={(newValue) => setPersonalFirstName(newValue)} />
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"middleName"} label={"Autres prénoms"} value={personalMiddleName}
                                      onChange={(newValue) => setPersonalMiddleName(newValue)} />
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"email"} label={"Email"} value={personalEmail} required={true}
                                      onChange={(newValue) => setPersonalEmail(newValue)} />
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"mobilePhoneNumber"} label={"N° Mobile"} value={personalMobilePhone}
                                      required={true}
                                      onChange={(newValue) => setPersonalMobilePhone(newValue)} />
                </Col>
                <Col lg={4}>
                    <WrappedTextField id={"homePhoneNumber"} label={"N° Domicile"} value={personalHomePhone}
                                      onChange={(newValue) => setPersonalHomePhone(newValue)} />
                </Col>
                <Col lg={4}>
                    <GoogleMapsField id={"postalAddress"} label={"Adresse postale"} value={personalAddress}
                                     required={true}
                                     onChange={(newValue) => setPersonalAddress(newValue)} />
                </Col>
                <Col lg={2}>
                    <WrappedDateField id={"birthDate"} label={"Date de naissance"} value={personalBirthDate}
                                      required={true}
                                      onChange={(newValue) => setPersonalBirthDate(newValue)} />

                </Col>
                <Col lg={1}>
                    <WrappedTextField id={"age"} label={"Age"} value={getAge(personalBirthDate)}
                                      disabled={true}
                                      onChange={() => null} />

                </Col>
                <Col lg={9}>
                    <WrappedTextField id={"description"} label={"Description"} value={personalDescription}
                                      multiline={true}
                                      onChange={(newValue) => setPersonalDescription(newValue)} />
                </Col>
                <Col lg={12} sx={{ m: 5 }}>
                    <div className="hstack gap-2 justify-content-end">
                        <WrappedButton
                            type={"submit"}
                            text={"Enregistrer"}
                            size={"large"}
                            color={"success"} />
                    </div>
                </Col>
            </Row>
        </Form>
    );
};
export default PersonalDetails;