import React, { useState, useCallback, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Container,
    Avatar, Paper, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { blue } from "@mui/material/colors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import withRouter from "../../../Components/Common/withRouter";
import { connect, getConnectedUser } from "../../../services/user-service";
import { Alert } from "reactstrap";
import { useDispatch } from "react-redux";
import { userUpdate } from "../../../reducers/user-reducer";

const LoginForm: React.FC = (props: any) => {

    const dispatch: any = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleEmailChange =
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        };

    const handlePasswordChange =
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
        };

    const setUserState = useCallback(() => { 
        getConnectedUser().then((user) => {
            if (user) {
                console.log(user);
                dispatch(userUpdate({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    profilePicture: user.profilePicture
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [dispatch]);

    const login = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            connect(email, password).then((response) => {
                if (response.success) {
                    setUserState();
                    props.router.navigate('/home');
                } else {
                    setErrorMessage(response.message);
                }
            }, (error) => {
                setErrorMessage(error);
            });
        },
        [email, password, setUserState, props.router]
    );

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
        }
    }, [errorMessage]);

    return (
        <Container component="main" maxWidth="xs">
            <Paper sx={{p: 5}} elevation={24} square={false}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Avatar sx={{bgcolor: blue[500]}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Connexion
                    </Typography>
                    {errorMessage && errorMessage ? (<Alert color="danger"> {errorMessage} </Alert>) : null}
                    <Box component="form" onSubmit={login} sx={{mt: 3}}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adresse Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <FormControl variant="outlined" required fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                value={password}
                                onChange={handlePasswordChange}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={
                                                showPassword ? 'hide the password' : 'display the password'
                                            }
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <div>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{mt: 2}}
                            >
                                Se connecter
                            </Button>
                        </div>

                    </Box>
                </Box>
            </Paper>
        </Container>
    )
        ;
};

export default withRouter(LoginForm);
