export class LocalStorageManager {
    public static set(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static get(key: string): string | null {
        const value = localStorage.getItem(key);
        return value !== null ? JSON.parse(value) : null;
    }

    public static remove(key: string): void {
        localStorage.removeItem(key);
    }
}
