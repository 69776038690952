import dayjs from "dayjs";

export const getAge = (personalBirthDate: string) => {
    if(!personalBirthDate) {
        return '';
    }
    
    const birthDate = dayjs(personalBirthDate);
    const currentDate = dayjs();

    const years = currentDate.diff(birthDate, 'year');
    if(isNaN(years)) {
        return '';
    }
    if (years > 0) {
        return `${years} ${years > 1 ? 'ans' : 'an'}`;
    }

    const months = currentDate.diff(birthDate, 'month');
    if(isNaN(months)) {
        return '';
    }
    if (months > 0) {
        return `${months} ${months > 1 ? 'mois' : 'mois'}`;
    }

    const days = currentDate.diff(birthDate, 'day');
    if(isNaN(days)) {
        return '';
    }
    return `${days} ${days > 1 ? 'jours' : 'jour'}`;
}