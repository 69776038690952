import { createSlice } from "@reduxjs/toolkit";
import avatar from "../assets/images/users/avatar-1.jpg";

export const initialState = {
    firstName: "",
    lastName: "",
    profilePicture: null as string | null,
};

const slice  = createSlice({
    name: "UserSlice",
    initialState,
    reducers: {
        userUpdate(state, action) {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.profilePicture = avatar;
        },
        userClear(state) {
            state.firstName = "";
            state.lastName = "";
            state.profilePicture = "";
        }
    },
});

export const {
    userUpdate,
    userClear
} = slice.actions

export default slice.reducer;