import React from 'react';
import withRouter from "../../../Components/Common/withRouter";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UsersGrid from "./grid/users-grid";
import { DefaultPageContentProps } from "../../DefaultPageContentProps";

const ManageUsers: React.FC<DefaultPageContentProps> = (props) => {
    document.title = props.title;
    return (
      <div>
            <BreadCrumb title={props.activePage} pageTitle={props.parentPage}/>
            <UsersGrid/>
      </div>
  );
};

export default withRouter(ManageUsers);
