import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { Chip, IconButton, Paper } from "@mui/material";
import { getAllUsers, User } from "../../../../services/user-service";
import WrappedButton from "../../../../Components/Custom/Buttons/wrapped-button";
import Grid2 from "@mui/material/Grid2";
import { Refresh, Visibility } from "@mui/icons-material";

function getRenderHeader() {
    return (params: GridColumnHeaderParams) => (
        <strong>
            {params.colDef.headerName}
        </strong>
    );
}

function standardColumnDef(id: string, headerName: string, flex?: number): GridColDef {
    return {
        field: id,
        headerName: headerName,
        flex: flex ? flex : 1,
        minWidth: 200,
        renderHeader: getRenderHeader(),
        headerAlign: 'center',
    };
}

const columns: GridColDef[] = [
    standardColumnDef('id', 'ID'),
    standardColumnDef('firstName', 'First name'),
    standardColumnDef('lastName', 'Last name'),
    standardColumnDef('email', 'Email'),
    standardColumnDef('address', 'Address', 2),
    {
        field: 'isActive',
        headerName: 'Status',
        sortable: false,
        width: 100,
        renderHeader: getRenderHeader(),
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            return params.value === 1 ? <Chip label="Actif" color="success"/> : <Chip label="Désactivé" color="error"/>;
        }
    },
    {
        field: 'buttons',
        headerName: 'Actions',
        width: 70,
        renderHeader: getRenderHeader(),
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<User>) => (
            <IconButton aria-label="view" color="info">
                <Visibility />
            </IconButton>
        ),
    }
];
const UsersGrid = () => {
    const [users, setUsers] = React.useState([] as any);
    const [refresh, setRefresh] = React.useState(false);

    useEffect(() => {
        const fetchData = async () => {
            getAllUsers().then((response) => {
                setUsers(response);
                console.log(response)
            }, (error) => {
                console.error(error);
            });
        }
        fetchData().then(r => r);
    }, [refresh]);

    return (
        <Paper sx={{p: 1}} square={false} elevation={5}>
            <Grid2 container spacing={2}>
                <Grid2>
                    <WrappedButton icon={<Refresh/>} text="Actualiser" onClick={()=> setRefresh(!refresh)}/>
                </Grid2>
                <Grid2 size={12}>
                    <div style={{height: '100%', width: '100%'}}>
                        <DataGrid
                            rows={users}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {page: 0, pageSize: 10},
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 20]}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                            sx={{
                                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                    "margin-top": "1em",
                                    "margin-bottom": "1em"
                                }
                            }}
                            checkboxSelection
                            disableRowSelectionOnClick
                            keepNonExistentRowsSelected
                        />
                    </div>
                </Grid2>
            </Grid2>
        </Paper>
    );
};

export default UsersGrid;