import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

interface NavigationStepItemProps {
    itemId: number;
    currentTab: number;
    stepTitle: string;
    updateSelectedTab: (selectedTabId: number) => void
}
const NavigationStepItem: React.FC<NavigationStepItemProps> = (props) => {
    const [activatedTabNumber, setActivatedTabNumber] = useState(props.currentTab);
    const [navLinkClasses, setNavLinkClasses] = useState("");

    const handleClick = () => {
        setActivatedTabNumber(props.itemId);
        props.updateSelectedTab(props.itemId);
    }

    useEffect(() => {
        setActivatedTabNumber(props.currentTab);
    }, [props.currentTab]);

    useEffect(() => {
        setNavLinkClasses(classnames({
            active: activatedTabNumber === props.itemId,
            done: activatedTabNumber >= props.itemId
        }));
    }, [activatedTabNumber, props.itemId]);

    return (
        <NavItem>
            <NavLink
                href="#"
                className={navLinkClasses}
                onClick={handleClick}>
                <span className="step-title me-2">
                    <i className="ri-close-circle-fill step-icon me-2"></i>
                    Step {props.itemId}
                </span>
                {props.stepTitle}
            </NavLink>
        </NavItem>
    );
}


export default NavigationStepItem;