import React from "react";
import BadgeIcon from "@mui/icons-material/Badge";
import SubCategoryMenu from "Layouts/Menu/SubCategoryMenu";
import PersonalDetails from "./personal-details";

const Profile: React.FC = (props: any) => {
    const tabContents = [
        { name: "Personal Details", element: <PersonalDetails />, icon: <BadgeIcon /> },
    ];
    return (
        <SubCategoryMenu tabContents={tabContents} />
    );
};

export default Profile;