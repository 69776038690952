import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getConnectedUser } from "../../services/user-service";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { userUpdate } from "../../reducers/user-reducer";

const ProfileDropdown: React.FC = () => {
    const dispatch: any = useDispatch();
    const globalState = (state: any) => state;
    const userState = createSelector(
        globalState,
        (state) => ({
            userFirstName: state.User.firstName,
            userLastName: state.User.lastName,
            userProfilePicture: state.User.profilePicture,
        })
    );
    const {
        userFirstName, userLastName, userProfilePicture
    } = useSelector(userState);

    const unknownUser = 'Unknown';

    const [connectedUserFirstName, setConnectedUserFirstName] = useState(unknownUser);
    const [connectedUserLastName, setConnectedUserLastName] = useState(unknownUser);
    const [connectedUserProfilePicture, setConnectedUserProfilePicture] = useState("");
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);

    const getUser = useCallback(() => {
        getConnectedUser().then((user) => {
            if (user) {
                dispatch(userUpdate({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    profilePicture: user.profilePicture
                }));
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [dispatch]);


    useEffect(() => {
        setConnectedUserFirstName(userFirstName);
        setConnectedUserLastName(userLastName);
        setConnectedUserProfilePicture(userProfilePicture);

        if(userFirstName === ""){
            getUser();
        }
    }, [userFirstName, userLastName, userProfilePicture, getUser]);


    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown}
                      className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={connectedUserProfilePicture}
                             alt="Header Avatar"/>
                        <span className="text-start ms-xl-2">
                            <span
                                className="d-none d-xl-inline-block ms-1 fw-medium user-name-text"> {connectedUserFirstName}</span>
                            <span
                                className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{connectedUserLastName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {connectedUserFirstName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to="/profile" className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Profile</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/apps-chat" className="dropdown-item">
                            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Messages</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to={"#"} className="dropdown-item">
                            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/pages-faqs" className="dropdown-item">
                            <i
                                className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span>
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem className='p-0'>
                        <Link to="/pages-profile" className="dropdown-item">
                            <i
                                className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$5971.67</b></span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/pages-profile-settings" className="dropdown-item">
                            <span
                                className="badge bg-success-subtle text-success mt-1 float-end">New</span><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Settings</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/auth-lockscreen-basic" className="dropdown-item">
                            <i
                                className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Lock screen</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link to="/logout" className="dropdown-item">
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Logout</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

export default ProfileDropdown;