import React from 'react';
import withRouter from "../../../Components/Common/withRouter";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { DefaultPageContentProps } from "../../DefaultPageContentProps";
import AddUserForm from "./form/add-user-form";
const AddUser: React.FC<DefaultPageContentProps> = (props) => {
    document.title = props.title;
    return (
        <div>
            <BreadCrumb title={props.activePage} pageTitle={props.parentPage}/>
            <AddUserForm/>
        </div>
    );
};

export default withRouter(AddUser);
