import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { createSelector } from "reselect";
import { getConnectedUser, User } from "../../services/user-service";
import Profile from "./index";

const UserProfile = () => {
    const dispatch: any = useDispatch();

    const [connectedUser, setConnectedUser] = useState<User | null>(null);
    const [profilePictureSrc, setProfilePictureSrc] = useState<string>(avatar);

    const selectLayoutState = (state: any) => state.Profile;
    const userprofileData = createSelector(
        selectLayoutState,
        (state) => ({
            user: state.user,
            success: state.success,
            error: state.error
        })
    );
    // Inside your component
    const {
        user, success, error
    } = useSelector(userprofileData);


    useEffect(() => {
        const fetchConnectedUser = async () => {
            const user = await getConnectedUser();
            setConnectedUser(user);
            if (user && user.profilePicture) {
                const profilePictureUrl = `data:image/*;base64,${user.profilePicture}`;
                setProfilePictureSrc(profilePictureUrl);
            }
        };

        fetchConnectedUser().then(r => r);
    }, [dispatch, user]);

    document.title = "Profile | Velzon - React Admin & Dashboard Template";
    return (
        <div>
            <Row>
                <Col lg="12">
                    {error && error ? <Alert color="danger">{error}</Alert> : null}
                    {success ? <Alert color="success">Username Updated To something</Alert> : null}

                    <Card>
                        <CardBody>
                            <div className="d-flex">
                                <div className="mx-3">
                                    <img
                                        src={profilePictureSrc}
                                        alt=""
                                        className="avatar-md rounded-circle img-thumbnail"
                                    />
                                </div>
                                <div className="flex-grow-1 align-self-center">
                                    <div className="text-muted">
                                        <h5>{connectedUser?.firstName} {connectedUser?.lastName}</h5>
                                        <p className="mb-1">Id : {connectedUser?.userId}</p>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Profile/>
        </div>
    );
};

export default UserProfile;
