import React from 'react';
import dayjs, { Dayjs } from "dayjs";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { frFR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/fr';
interface WrappedDateFieldProps {
    id: string;
    label: string;
    value: string;
    required: boolean;
    onChange: (newValue: string) => void;
}

const WrappedDateField: React.FC<WrappedDateFieldProps> = (props) => {
    const handleChange = (event: Dayjs | null) => {
        props.onChange(event ? event.format('YYYY-MM-DD') : '');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}
                              localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
                              adapterLocale="fr">
            <DemoContainer components={['DateField']}>
                <DateField
                    sx={{pt:1, pb:1}}
                    id={props.id}
                           name={props.id}
                           label={props.label}
                           value={props.value ? dayjs(props.value) : null}
                           required = {props.required}
                           onChange={handleChange}
                           variant="outlined"
                           margin="normal"
                           fullWidth
                           autoFocus/>
            </DemoContainer>
        </LocalizationProvider>

    );
};
export default WrappedDateField;