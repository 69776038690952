import React, { useState } from "react";
import Grid2 from "@mui/material/Grid2";
import { Button, ButtonGroup } from "@mui/material";
import WrappedTextField from "../../../../Components/Custom/TextFields/wrapped-text-field";
import GoogleMapsField from "../../../../Components/Custom/TextFields/google-maps-field";
import WrappedDateField from "../../../../Components/Custom/TextFields/wrapped-date-field";
import { getAge } from "../../../../utils/date-helper";
import { Form } from "reactstrap";
import { EntityStorageManager } from "../../../../utils/state/entity-storage-manager";
import { EntityStorageScope } from "../../../../utils/state/entity-storage-scope";
import { EntityStorageType } from "../../../../utils/state/entity-storage-type";
import { EntityStorageKey } from "../../../../utils/state/entity-storage-keys";

interface FirstStepFormProps {
    onValidation: (result: boolean) => void
}

const FirstStepForm: React.FC<FirstStepFormProps> = (props) => {

    const userCreationInfoStorageManager = new EntityStorageManager(EntityStorageType.User, EntityStorageScope.Create);
    const userInfo = userCreationInfoStorageManager.getOrDefault(EntityStorageKey.Info, null);
    
    
    const [personalUserId, setPersonalUserId] = useState("");
    const [personalUsername, setPersonalUsername] = useState("");
    const [personalGender, setPersonalGender] = useState(userInfo ? userInfo.gender : -1);
    const [personalFirstName, setPersonalFirstName] = useState(userInfo ? userInfo.firstName : "");
    const [personalMiddleName, setPersonalMiddleName] = useState(userInfo ? userInfo.middleName : "");
    const [personalLastName, setPersonalLastName] = useState(userInfo ? userInfo.lastName : "");
    const [personalEmail, setPersonalEmail] = useState(userInfo ? userInfo.email : "");
    const [personalHomePhone, setPersonalHomePhone] = useState(userInfo ? userInfo.homePhone : "");
    const [personalMobilePhone, setPersonalMobilePhone] = useState(userInfo ? userInfo.mobilePhone : "");
    const [personalAddress, setPersonalAddress] = useState(userInfo ? userInfo.address : "");
    const [personalBirthDate, setPersonalBirthDate] = useState(userInfo ? userInfo.birthDate : "");
    const [personalDescription, setPersonalDescription] = useState(userInfo ? userInfo.description : "");
    const [personalIsActive, setPersonalIsActive] = useState(-1);

    const buttons = [
        <Button size="small" key="male" onClick={() => setPersonalGender(0)}
                variant={personalGender === 0 ? "contained" : "outlined"}>MR.</Button>,
        <Button size="small" key="female" onClick={() => setPersonalGender(1)}
                variant={personalGender === 1 ? "contained" : "outlined"}>MME.</Button>,
    ];

    const validate = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newUser = {
            gender: personalGender,
            firstName: personalFirstName,
            middleName: personalMiddleName,
            lastName: personalLastName,
            email: personalEmail,
            homePhone: personalHomePhone,
            mobilePhone: personalMobilePhone,
            address: personalAddress,
            birthDate: personalBirthDate,
            description: personalDescription,
        };

        userCreationInfoStorageManager.set(EntityStorageKey.Info, newUser);
        
        props.onValidation(true);
    }

    return (
        <div>
            <div>
                <h5>Personal Info</h5>
                <p className="text-muted">
                    Fill all information below
                </p>
            </div>
            <Form onSubmit={validate}>
                <div>

                    <Grid2 container spacing={2}>
                        <Grid2 size={12}>
                            <ButtonGroup size="large" aria-label="Large button group">
                                {buttons}
                            </ButtonGroup>
                        </Grid2>
                        <Grid2 size={4}>

                            <WrappedTextField id={"lastName"} label={"Nom"}
                                              value={personalLastName} required={true}
                                              onChange={(newValue) => setPersonalLastName(newValue)}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <WrappedTextField id={"firstName"} label={"Prénom principal"}
                                              value={personalFirstName}
                                              required={true}
                                              onChange={(newValue) => setPersonalFirstName(newValue)}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <WrappedTextField id={"middleName"} label={"Autres prénoms"}
                                              value={personalMiddleName}
                                              onChange={(newValue) => setPersonalMiddleName(newValue)}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <WrappedTextField id={"email"} label={"Email"} value={personalEmail}
                                              required={true}
                                              onChange={(newValue) => setPersonalEmail(newValue)}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <WrappedTextField id={"mobilePhoneNumber"} label={"N° Mobile"}
                                              value={personalMobilePhone}
                                              required={true}
                                              onChange={(newValue) => setPersonalMobilePhone(newValue)}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <WrappedTextField id={"homePhoneNumber"} label={"N° Domicile"}
                                              value={personalHomePhone}
                                              onChange={(newValue) => setPersonalHomePhone(newValue)}/>
                        </Grid2>
                        <Grid2 size={6}>
                            <GoogleMapsField id={"postalAddress"} label={"Adresse postale"}
                                             value={personalAddress}
                                             required={true}
                                             onChange={(newValue) => setPersonalAddress(newValue)}/>
                        </Grid2>
                        <Grid2 size={3}>
                            <WrappedDateField id={"birthDate"} label={"Date de naissance"}
                                              value={personalBirthDate}
                                              required={true}
                                              onChange={(newValue) => setPersonalBirthDate(newValue)}/>

                        </Grid2>
                        <Grid2 size={2}>
                            <WrappedTextField id={"age"} label={"Age"}
                                              value={getAge(personalBirthDate)}
                                              disabled={true}
                                              onChange={() => null}/>

                        </Grid2>
                        <Grid2 size={12}>
                            <WrappedTextField id={"description"} label={"Description"}
                                              value={personalDescription}
                                              multiline={true}
                                              onChange={(newValue) => setPersonalDescription(newValue)}/>
                        </Grid2>
                    </Grid2>
                </div>

                <div className="d-flex align-items-start gap-3 mt-4">
                    <button
                        type="submit"
                        className="btn btn-success btn-label right ms-auto nexttab nexttab">
                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        Go to Next Step
                    </button>
                </div>
            </Form>
        </div>
    );
}

export default FirstStepForm;