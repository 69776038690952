import * as React from "react";
import { Button } from "@mui/material";

interface WrappedButtonProps {
    classes?: any;
    icon?: React.JSX.Element;
    onClick?: (target: HTMLElement) => Promise<void> | void;  // Permet de gérer les promesses pour attendre la fin de l'action
    text?: string;
    size?: "small" | "medium" | "large";
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    fullWidth?: boolean | false;
    type?: "button" | "submit" | "reset";
}


const WrappedButton: React.FC<WrappedButtonProps> = (props) => {
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        if (props.onClick) {
            await props.onClick(event.target as HTMLElement);
        }
    };

    return (
        <Button
            type={props.type}
            onClick={handleClick}
            size={props.size}
            color={props.color}
            fullWidth={props.fullWidth}
            variant={"contained"}
        >
            {props.icon}
            {props.text}
        </Button>
    );
};

export default WrappedButton;
