import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const handleSnackbarClose = (setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>) =>
    (_?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

interface CustomSnackbarProps {
    open: boolean;
    onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
    message: string;
    severity: "error" | "warning" | "info" | "success";
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({open, onClose, message, severity}) => {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}
                  anchorOrigin={{vertical: "top", horizontal: "center"}}>
            <Alert onClose={onClose} severity={severity} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;