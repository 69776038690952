import { APIClient } from "../helpers/api_helper";
import axios from "axios";
import config from "../config";
const { api } = config;

interface ConnectResponse {
    success: boolean;
    message: string;
}
export interface User {
    userId: string;
    gender: number;
    username: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    homePhone: string;
    mobilePhone: string;
    address: string;
    birthDate: string;
    description: string;
    isActive: number;
    profilePicture: [] | null;
}

const clientApi = new APIClient();

export const getAllUsers = async () => clientApi.get(`/users/all`);

export const getConnectedUser = async (): Promise<User | null> => {
    const authUser: any = sessionStorage.getItem("authUser");
    if (authUser) {
        const authUserInfos: any = JSON.parse(authUser);
        let getConnectedUser = async () => {
            try {
                var parsedAuthUser = JSON.parse(authUser);
                const user: any = await axios.get(`${api.API_URL}/users/${authUserInfos.currentUserId}`, {
                    headers: {
                        Authorization: `Bearer ${parsedAuthUser.token}`,
                    }
                });
                return user;
            } catch (error) {
                throw error;
            }
        };
        return getConnectedUser().then(r => r);
    }
    return null;
};
export const connect = async (login: string, password: string): Promise<ConnectResponse> => {
    try {
        const response = await clientApi.post(`/auth/signin`, {username: login, password: password});
        const authUserResponse = JSON.stringify(response)
        const parsedResponse: any = JSON.parse(authUserResponse);
        if (parsedResponse.status === "success") {
            sessionStorage.setItem("authUser", authUserResponse);
            return {
                success: true,
                message: 'Login successful'
            };
        }

        return {
            success: false,
            message: 'Unexpected response status' // Ceci est un cas inattendu
        };

    } catch (error: any) {
        return {
            success: false,
            message: error?.message ? error.message : error.response?.data?.message || 'An error occurred during login'
        };
    }
};
export const disconnect = () => {
    sessionStorage.removeItem("authUser");
};

export const updateProfile = async (user: User) => {
    return clientApi.post(`/users/update-details`, user);
}

export const createUser = async (user: any) => {
    return clientApi.post(`/users/create`, user);
}

export const getFamilies = async () => {
    return await clientApi.get(`/users/families`);
}

export const getGroups = async () => {
    return await clientApi.get(`/users/groups`);
}